<template>
  <div>
    <Screening :show-export="false">
      <template #screening>
        <div
          v-if="props.tableType !== 'journalType' && props.tableType !== 'auxiliaryCode'"
          class="elv-ledger-screening-item"
        >
          <span class="elv-ledger-screening-item__label">{{ t('common.list') }}:</span>
          <el-select
            v-model="listValue"
            placeholder=" "
            popper-class="elv-ledger-screening-popper"
            class="elv-ledger-screening-list"
            @change="onCheckTableExpand"
          >
            <el-option v-for="item in listOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div
          v-if="
            props.tableType !== 'chartOfAccount' &&
            props.tableType !== 'journalType' &&
            props.tableType !== 'auxiliaryCode'
          "
          class="elv-ledger-screening-period"
        >
          <div class="elv-ledger-screening-period__label">{{ t('common.period') }}</div>
          <div ref="dateTimeRef" v-click-outside="onCloseDateTime" @click="onOpenDateTime">
            <div class="elv-ledger-screening-period__value">
              <span v-if="dateTimeValue[0] && dateTimeValue[1]"
                >{{ dayjs(dateTimeValue[0])?.format('YYYY/MM/DD') }} -
                {{ dayjs(dateTimeValue[1]).format('YYYY/MM/DD') }}</span
              >
            </div>
            <SvgIcon name="arrow-down-line" width="16" height="16" :style="{ transform: rotate }" />
          </div>
        </div>
        <div v-show="false">
          <el-date-picker
            ref="popoverRef"
            v-model="dateTimeValue"
            popper-class="elv-ledger-screening-dateTime-popper"
            :virtual-ref="dateTimeRef"
            virtual-triggering
            type="datetimerange"
            value-format="YYYY-MM-DD"
            :clearable="false"
            :shortcuts="shortcuts"
            range-separator="To"
            :disabled-date="disabledDate"
            :default-value="defaultTime"
            start-placeholder="Start date"
            end-placeholder="End date"
            @change="onChangeTime"
          />
        </div>
        <template v-if="props.tableType === 'accountBalance'">
          <div class="elv-ledger-screening-item">
            <span class="elv-ledger-screening-item__label">{{ t('common.group') }}:</span>
            <el-select
              v-model="groupValue"
              placeholder=" "
              popper-class="elv-ledger-screening-popper is-group"
              class="elv-ledger-screening-list"
              @change="emit('onChangeGroup', 'GROUP')"
            >
              <el-option
                v-if="groupOptions[0]?.value === 'CHART_OF_ACCOUNT'"
                :label="groupOptions[0]?.label"
                :value="groupOptions[0]?.value"
              />
              <el-option-group
                v-for="group in groupOptions.filter((item) => item?.value !== 'CHART_OF_ACCOUNT')"
                :key="group.label"
                :label="group.label"
              >
                <template v-if="group?.options">
                  <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value" />
                </template>
              </el-option-group>
            </el-select>
          </div>
          <div class="elv-ledger-screening-item">
            <span class="elv-ledger-screening-item__label">{{ t('common.subgroup') }}:</span>
            <el-select
              v-model="subGroup"
              placeholder=" "
              popper-class="elv-ledger-screening-popper is-group"
              class="elv-ledger-screening-list"
              @change="emit('onChangeGroup', 'SUB_GROUP')"
            >
              <el-option
                v-if="subGroupOptions[0]?.value === 'CHART_OF_ACCOUNT'"
                :label="subGroupOptions[0]?.label"
                :value="subGroupOptions[0]?.value"
              />
              <el-option-group
                v-for="group in subGroupOptions.filter((item) => !['CHART_OF_ACCOUNT', 'NONE'].includes(item?.value))"
                :key="group.label"
                :label="group.label"
              >
                <template v-if="group?.options">
                  <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value" />
                </template>
              </el-option-group>
              <el-option
                v-if="subGroupOptions[subGroupOptions.length - 1]?.value === 'NONE'"
                :label="subGroupOptions[subGroupOptions.length - 1]?.label"
                :value="subGroupOptions[subGroupOptions.length - 1]?.value"
              />
            </el-select>
          </div>
        </template>
      </template>
      <template #operating>
        <Operating
          :table-type="props.tableType"
          :export-params="props.exportParams"
          @onAddNew="emit('onAddNew')"
          @onOpenTreasuryAccountMappingDialog="emit('onOpenTreasuryAccountMappingDialog')"
          @onOpenChartOfAccountMappingDialog="emit('onOpenChartOfAccountMappingDialog')"
          @onOpenOpeningBalanceDialog="emit('onOpenOpeningBalanceDialog')"
        />
      </template>
    </Screening>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import Operating from './Operating.vue'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'
import { ClickOutside as vClickOutside } from 'element-plus'
import Screening from '@/pages/Financials/Project/components/Screening.vue'

type ExportParamsType = {
  type: string
  extra: Object
}

const props = defineProps({
  defaultListType: {
    type: String,
    default: 'COLLAPSE'
  },
  tableType: {
    type: String,
    default: 'chartOfAccount'
  },
  defaultTime: {
    type: Array,
    default: () => ['', '']
  },
  exportParams: {
    type: Object as () => ExportParamsType,
    default: () => {
      return {}
    }
  },
  groupOptions: {
    type: Array<any>,
    default: () => []
  },
  subGroupOptions: {
    type: Array<any>,
    default: () => []
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)

const { t } = useI18n()
const entityStore = useEntityStore()

const groupValue = defineModel('group', { type: String })
const subGroup = defineModel('subGroup', { type: String })

const popoverRef = ref()
const dateTimeRef = ref()
const dateTimeValue: any = ref(['', ''])
const rotate = ref('rotate(0deg)')

const shortcuts = [
  {
    text: 'All Time',
    value: () => {
      const start = entityStore.entityDetail?.creationDate
        ? dayjs.tz(entityStore.entityDetail?.creationDate, entityStore.entityDetail?.timezone).format('YYYY-MM-DD')
        : dayjs.tz(entityStore.entityDetail?.createdAt, entityStore.entityDetail?.timezone).format('YYYY-MM-DD')
      const end = entityStore.entityDetail?.progress?.postingDoneTime
        ? dayjs
            .tz(entityStore.entityDetail?.progress?.postingDoneTime, entityStore.entityDetail?.timezone)
            .subtract(1, 'day')
            .format('YYYY-MM-DD')
        : dayjs().tz(entityStore.entityDetail?.timezone).subtract(1, 'day').format('YYYY-MM-DD')
      return [start, end]
    }
  },
  {
    text: 'Last 7d',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: 'Last 30d',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  }
]

const emit = defineEmits([
  'onAddNew',
  'onChangeTime',
  'onChangeGroup',
  'onCheckTableExpand',
  'onOpenOpeningBalanceDialog',
  'onOpenTreasuryAccountMappingDialog',
  'onOpenChartOfAccountMappingDialog'
])

const listValue = ref('COLLAPSE')

const listOptions = reactive([
  {
    value: 'COLLAPSE',
    label: t('common.collapseAll')
  },
  {
    value: 'EXPAND',
    label: t('common.expandAll')
  }
])

const contentPadding = computed(() => {
  return props.tableType === 'journalType' || props.tableType === 'chartOfAccount' ? '8px' : '12px'
})

const disabledDate = (time: Date) => {
  return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
}

const onCheckTableExpand = () => {
  emit('onCheckTableExpand', listValue.value)
}

const onOpenDateTime = () => {
  rotate.value = rotate.value === 'rotate(0deg)' ? 'rotate(-180deg)' : 'rotate(0deg)'
  if (rotate.value === 'rotate(0deg)') {
    unref(popoverRef)?.handleClose?.()
  } else {
    unref(popoverRef)?.handleOpen?.()
  }
}

const onCloseDateTime = () => {
  rotate.value = 'rotate(0deg)'
  unref(popoverRef)?.handleClose?.()
}

defineExpose({ dateTimeValue })
const onChangeTime = () => {
  rotate.value = 'rotate(0deg)'
  emit('onChangeTime')
}

watch(
  () => props.defaultTime,
  () => {
    if (props.defaultTime.length && (props.tableType === 'accountBalance' || props.tableType === 'generalLedger')) {
      dateTimeValue.value = props.defaultTime
    }
  },
  { immediate: true }
)

watch(
  () => props.defaultListType,
  () => {
    listValue.value = props.defaultListType
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
:deep(.elv-screening-container) {
  padding-top: v-bind('contentPadding');
  padding-bottom: v-bind('contentPadding');
}
</style>

<style lang="scss">
.elv-ledger-screening-item {
  padding-right: 16px;
  display: flex;
  align-items: center;

  &:last-child {
    border-right: none;
  }

  .elv-ledger-screening-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.elv-ledger-screening-period {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 16px;
  padding-left: 16px;
  border-left: 1px solid #dde1e6;
  margin-right: 16px;

  &.is-disabled .elv-ledger-screening-period__label + div {
    cursor: not-allowed;
  }

  .elv-ledger-screening-period__label {
    color: #838d95;
    margin-right: 8px;

    + div {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  .elv-ledger-screening-period__value {
    color: #1e2024;
    margin-right: 4px;
  }

  &:not(.is-disabled):hover {
    .elv-ledger-screening-period__icon {
      fill: #5e85eb;
    }
  }
}

.elv-ledger-screening-dateTime-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-range-picker {
    &.has-sidebar {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }

    // .el-picker-panel__icon-btn.d-arrow-left,
    // .el-picker-panel__icon-btn.d-arrow-right {
    //   display: none;
    // }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}

.elv-ledger-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  &.is-group {
    .el-select-dropdown__item {
      padding: 0 8px;
    }

    .el-select-group__title {
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-bottom: 0px;
      border-top: 1px solid #edf0f3;
    }
  }

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
